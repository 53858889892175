<template>
  <div>
    <div v-if="checkLogs">
      <v-row no-gutters justify="space-between" align="center">
        <v-col />
        <v-col cols="4">
          <search-text-field v-model="search" />
        </v-col>
        <v-col />
      </v-row>
      <v-data-table
        @click:row="showDetails"
        dense
        :headers="headers"
        :items="checkLogs"
        :options.sync="options"
        :server-items-length="totalLogs"
        sort-by="created"
        sort-desc
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 250, 1000] }"
      >
        <template v-slot:[`item.user.email`]="{ item }">
          <router-link :to="{ name: 'UserDetails', params: { Id: item.userId } }">{{ item.user.email }}</router-link>
        </template>
        <template v-slot:[`item.application.name`]="{ item }">
          <router-link :to="{ name: 'ApplicationDetails', params: { Id: item.applicationId } }">{{ item.application.name }}</router-link>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ $util.formatDate(item.created, true) }}
        </template>
        <template v-slot:[`item.type`]=""> Update check </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
import _ from 'lodash';
import apiService from '@/services/apiService.js';

export default {
  name: 'CheckLogList',
  components: {},
  data: () => ({
    checkLogs: [],
    totalLogs: 0,
    isLoading: false,
    search: null,
    options: {
      page: 1,
      sortBy: ['created'],
      itemsPerPage: 25,
      sortDesc: [true],
    },
    headers: [
      {
        text: 'Timestamp',
        value: 'created',
      },
      {
        text: 'User',
        value: 'user.email',
      },
      {
        text: 'Application',
        value: 'application.name',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Version',
        value: 'version',
      },
      {
        text: 'Remote Address',
        value: 'remoteAddress',
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.getCheckLogs();
      },
      deep: true,
    },
    search() {
      this.getCheckLogsDebounce();
    },
  },
  methods: {
    async getCheckLogs() {
      const params = {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        search: this.search,
      };

      if (this.options.sortBy?.length > 0) {
        params.sortBy = this.options.sortBy[0];
        params.sortDesc = this.options.sortDesc[0];
      }

      this.isLoading = true;
      const result = await apiService.getUpdateCheckLogs(params);
      this.checkLogs = result.data;
      this.totalLogs = result.paging.totalCount;
      this.isLoading = false;
    },
    showDetails(checkLog) {
      this.$router.push({ name: 'CheckLogDetails', params: { Id: checkLog.id } });
    },
  },

  created() {
    this.getCheckLogsDebounce = _.debounce(this.getCheckLogs, 200);
    this.getCheckLogs();
  },
};
</script>

<style>
</style>